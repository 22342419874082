<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.02579 4.67677C7.02579 3.06645 8.55559 1.7782 10.5685 1.7782C13.145 1.7782 14.6748 2.98594 15.5605 9.10514H16.1241L16.9292 0.731494C11.1321 -1.2814 4.0467 0.892528 3.96619 6.8507C3.96619 13.3725 14.3527 13.3725 14.3527 19.3306C14.3527 21.102 12.8229 22.2292 11.0516 22.2292C6.46218 22.2292 5.41547 18.3645 5.17393 14.3387H4.5298L3 21.9072C3 21.9072 6.38166 23.92 10.0854 23.92C14.5138 23.8395 17.4123 20.6189 17.5734 17.2372C17.5734 10.7155 7.02579 9.34669 7.02579 4.67677Z" fill="white" fill-opacity="0.8"/>
    <path d="M18 22C18 20.9655 18.8276 20 20 20C21.1724 20 22 20.8966 22 22C22 23.1034 21.1724 24 20 24C18.8276 24 18 23.0345 18 22Z" fill="white" fill-opacity="0.8"/>
  </svg>
</template>

<script>
export default {
  name: "SalesloftLogo"
}
</script>

<style>

</style>
