<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3981 1C9.39623 1 8.23549 1.90841 7.68035 2.51402C7.61306 1.9757 7.25979 1.28598 5.88035 1.28598H2.86914V4.41495H4.09718C4.29905 4.41495 4.36633 4.48224 4.36633 4.68411V19H7.93268V13.6505V13.2467C8.48782 13.7514 9.54763 14.4579 11.2131 14.4579C14.6953 14.4579 17.1345 11.6991 17.1345 7.72897C17.1514 3.70841 14.8299 1 11.3981 1ZM10.6747 11.3458C8.75698 11.3458 7.88222 9.51215 7.88222 7.79626C7.88222 5.10467 9.34577 4.14579 10.7252 4.14579C12.4074 4.14579 13.5514 5.59252 13.5514 7.76262C13.5345 10.2523 12.0878 11.3458 10.6747 11.3458Z" fill="white" fill-opacity="1" />
  </svg>
</template>

<script>
export default {
  name: "PipedriveLogo"
}
</script>

<style>

</style>
