<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0323 0L0 4.64933V7.74888L12.0323 12.4628L24 7.74888V4.64933L12.0323 0ZM0 16.2511V19.2861L12.0323 24L24 19.2861V16.2511L12.0323 20.9004L0 16.2511ZM0 10.4179V13.5175L12.0323 18.2314L24 13.5175V10.4179L12.0323 15.1318L0 10.4179Z" fill="white" fill-opacity="1" />
  </svg>

</template>

<script>
export default {
  name: "SugarCrmLogo"
}
</script>

<style>

</style>
