<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16786_18802)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25.5 36H10.5V33.987C13.212 32.5095 13.5075 30.6795 13.5 28.5H1.5C1.1025 28.5 0.72 28.3425 0.4395 28.0605C0.1575 27.78 0 27.3975 0 27V1.5C0 1.1025 0.1575 0.72 0.4395 0.4395C0.72 0.1575 1.1025 0 1.5 0H34.5C34.8975 0 35.28 0.1575 35.5605 0.4395C35.8425 0.72 36 1.1025 36 1.5V27C36 27.3975 35.8425 27.78 35.5605 28.0605C35.28 28.3425 34.8975 28.5 34.5 28.5H22.5C22.506 30.561 22.7325 32.49 25.5 33.987V36ZM19.524 28.5H16.458C16.4355 29.703 16.464 31.5045 15.3795 33H20.619C19.4835 31.47 19.545 29.625 19.524 28.5ZM33 21H3V25.5H33V21ZM18 21.8055C18.828 21.8055 19.5 22.4775 19.5 23.3055C19.5 24.1335 18.828 24.8055 18 24.8055C17.172 24.8055 16.5 24.1335 16.5 23.3055C16.5 22.4775 17.172 21.8055 18 21.8055ZM3 3V18H33V3H3Z" fill="white" />
      <path d="M12.8906 9.09585H11.015C10.8311 8.31127 10.2304 7.85769 9.36005 7.85769C8.01156 7.85769 7.23924 8.85067 7.23924 10.5669C7.23924 12.271 8.01156 13.2885 9.31101 13.2885C10.255 13.2885 10.8189 12.8226 11.0395 11.8419H12.9887C12.768 13.693 11.3828 14.8454 9.36005 14.8454C6.94502 14.8454 5.40039 13.1781 5.40039 10.5669C5.40039 7.9435 6.92051 6.30078 9.34779 6.30078C11.3215 6.30078 12.67 7.35506 12.8906 9.09585Z" fill="white" />
      <path d="M16.1203 11.3883V14.6615H14.3182V6.48467H17.8856C18.9399 6.48467 19.5283 6.61952 20.0187 6.97503C20.5213 7.3428 20.8032 7.95576 20.8032 8.6913C20.8032 9.68429 20.3619 10.3218 19.4425 10.665C20.2516 10.9225 20.5335 11.3515 20.5948 12.4303L20.6561 13.5949C20.6929 14.1343 20.7542 14.3182 20.9871 14.6615H19.1851C18.9644 14.4898 18.9031 14.3673 18.8786 13.9504L18.805 12.8349C18.7315 11.8296 18.2902 11.3883 17.3462 11.3883H16.1203ZM16.1203 9.94173H17.9224C18.5966 9.94173 18.9644 9.59847 18.9644 8.99778C18.9644 8.39708 18.5966 8.05383 17.9224 8.05383H16.1203V9.94173Z" fill="white" />
      <path d="M27.4621 14.6615H25.6478L24.5199 10.3095C24.4341 9.94173 24.3606 9.68429 24.3115 9.51266C24.2502 9.25522 24.238 9.20618 24.1644 8.97326C24.1889 9.26748 24.1889 9.47588 24.1889 9.61073L24.2012 10.3953V14.6615H22.5217V6.48467H25.1084L26.1504 10.7631C26.3097 11.4006 26.4446 12.0748 26.5427 12.6142C26.5794 12.4548 26.5917 12.369 26.5917 12.3568L26.7265 11.8296C26.7511 11.6825 26.8001 11.4986 26.8491 11.278C26.9104 11.0205 26.9717 10.7631 26.9717 10.7386L28.0383 6.48467H30.6004V14.6615H28.9209V10.3953L28.9332 9.61073C28.9332 9.45137 28.9332 9.26748 28.9577 8.97326C28.8228 9.46362 28.737 9.79462 28.7002 9.90495C28.6267 10.1256 28.5899 10.285 28.5777 10.2972L27.4621 14.6615Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_16786_18802">
        <rect width="36" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "DefaultCrmLogo"
}
</script>

<style>

</style>
