<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.1171 7.29533V5.40052C14.3683 5.28314 14.581 5.09676 14.7303 4.8631C14.8797 4.62945 14.9595 4.35815 14.9605 4.08085V4.03638C14.9595 3.64947 14.8053 3.2787 14.5317 3.00511C14.2582 2.73152 13.8874 2.57735 13.5005 2.5763H13.4561C13.0688 2.57683 12.6975 2.73074 12.4234 3.00438C12.1493 3.27801 11.9948 3.64908 11.9936 4.03638V4.08085C11.995 4.35633 12.0742 4.6258 12.222 4.85828C12.3698 5.09076 12.5802 5.2768 12.829 5.39503L12.837 5.40002V7.29983C12.1121 7.40987 11.4295 7.71081 10.8593 8.17178L10.8673 8.16678L5.65192 4.10434C6.1231 2.34494 3.82767 1.21815 2.72443 2.66774C1.61819 4.11483 3.30753 6.03013 4.88195 5.1142L4.87396 5.1192L10.0004 9.10869C9.54732 9.78928 9.30646 10.5891 9.30841 11.4067C9.30841 12.2992 9.59221 13.1297 10.0734 13.8087L10.0654 13.7957L8.50396 15.3548C8.37905 15.3158 8.24914 15.2948 8.11872 15.2923H8.11623C6.91155 15.2923 6.30497 16.7524 7.15839 17.6058C8.0118 18.4568 9.4718 17.8532 9.4718 16.6479C9.46903 16.5126 9.4463 16.3784 9.40434 16.2497L9.40684 16.2602L10.9503 14.7167C11.4538 15.1008 12.0382 15.3651 12.6591 15.4896C13.28 15.6141 13.9212 15.5955 14.5338 15.4352C15.1457 15.274 15.7129 14.9754 16.1922 14.5622C16.6715 14.149 17.0503 13.632 17.2999 13.0505C17.5495 12.4689 17.6633 11.8382 17.6326 11.2061C17.602 10.574 17.4277 9.95717 17.123 9.4025C16.8184 8.84791 16.3911 8.37021 15.8738 8.00582C15.3565 7.64142 14.7629 7.39996 14.1381 7.29983L14.1121 7.29483L14.1171 7.29533ZM13.474 13.5439C11.5719 13.5389 10.6245 11.2379 11.9726 9.89519C13.3182 8.55204 15.6161 9.50494 15.6161 11.4072V11.4097C15.6161 11.6902 15.5608 11.9679 15.4534 12.227C15.346 12.4861 15.1886 12.7215 14.9901 12.9197C14.7917 13.1179 14.5562 13.2751 14.297 13.3822C14.0378 13.4893 13.76 13.5442 13.4795 13.5439H13.474Z" fill="white" fill-opacity="1"/>
  </svg>
</template>

<script>
export default {
  name: "HubspotLogo"
}
</script>

<style>

</style>
