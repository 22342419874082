<template>
  <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.85841 6.59339H8.26597C8.10994 6.59339 7.96031 6.65537 7.84998 6.76569C7.73966 6.87602 7.67768 7.02565 7.67768 7.18168V9.71297C7.67768 9.869 7.73966 10.0186 7.84998 10.129C7.96031 10.2393 8.10994 10.3013 8.26597 10.3013H8.88741C11.6052 10.3013 13.9811 12.5218 14.0309 15.2396C14.0435 15.9159 13.9196 16.5879 13.6665 17.2153C13.4134 17.8427 13.0364 18.4125 12.5579 18.8908C12.0794 19.369 11.5094 19.7459 10.8819 19.9987C10.2545 20.2515 9.58243 20.3752 8.90605 20.3622C6.19036 20.3167 3.96768 17.9387 3.96768 15.2209V0.586217C3.96768 0.430742 3.90592 0.281636 3.79598 0.171699C3.68605 0.061762 3.53694 0 3.38146 0H0.86049C0.704823 -9.64988e-07 0.5555 0.0616947 0.445233 0.171573C0.334965 0.28145 0.272743 0.430552 0.272195 0.586217L0.259766 15.3349C0.279544 17.6527 1.21928 19.8677 2.87224 21.4927C3.6907 22.2973 4.65963 22.9328 5.7237 23.363C6.78777 23.7931 7.92615 24.0095 9.07384 23.9997C10.2215 23.9899 11.3561 23.7541 12.4126 23.3059C13.4692 22.8577 14.4271 22.2057 15.2318 21.3872C16.0364 20.5688 16.6719 19.5999 17.1021 18.5358C17.5322 17.4718 17.7486 16.3334 17.7388 15.1857C17.6621 10.3924 13.6518 6.59339 8.85841 6.59339Z" fill="white" fill-opacity="0.8"/>
  </svg>
</template>

<script>
export default {
  name: "BaoLogo"
}
</script>

<style>

</style>
