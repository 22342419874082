import DefaultCrmLogo from "@/apps/base/svgs/crmLogos/DefaultCrmLogo.vue"
import Dynamics365Logo from "@/apps/base/svgs/crmLogos/Dynamics365Logo.vue"
import HubspotLogo from "@/apps/base/svgs/crmLogos/HubspotLogo.vue"
import SalesforceLogo from "@/apps/base/svgs/crmLogos/SalesforceLogo.vue"
import ZendeskLogo from "@/apps/base/svgs/crmLogos/ZendeskLogo.vue"
import ZohoLogo from "@/apps/base/svgs/crmLogos/ZohoLogo.vue"
import PipedriveLogo from "@/apps/base/svgs/crmLogos/PipedriveLogo.vue"
import SugarCrmLogo from "@/apps/base/svgs/crmLogos/SugarCrmLogo.vue"
import SalesloftLogo from "@/apps/base/svgs/crmLogos/SalesloftLogo.vue"
import PlentyMarketsLogo from "@/apps/base/svgs/crmLogos/PlentyMarketsLogo.vue"
import BaoLogo from "@/apps/base/svgs/crmLogos/BaoLogo.vue"
import AircallLogo from "@/apps/base/svgs/crmLogos/AircallLogo.vue"

const CrmLogoMixin = {
  name: "CrmLogoMixin",
  computed: {
    crmLogoObject () {
      const logoObject = {
        hubspot: HubspotLogo,
        zendesk: ZendeskLogo,
        pipedrive: PipedriveLogo,
        pipedrive_2: PipedriveLogo,
        salesforce: SalesforceLogo,
        salesforce_dev: SalesforceLogo,
        zoho: ZohoLogo,
        dynamics365: Dynamics365Logo,
        dynamics365_2: Dynamics365Logo,
        sugarcrm: SugarCrmLogo,
        aircall: AircallLogo,
        bao: BaoLogo,
        salesloft: SalesloftLogo,
        salesloft_old: SalesloftLogo,
        plentymarkets: PlentyMarketsLogo,
        default: DefaultCrmLogo
      }
      return logoObject
    }
  },
  methods: {
    getCrmLogo (crmService) {
      return this.crmLogoObject[crmService] || this.crmLogoObject.default
    }
  }
}

export default CrmLogoMixin
