<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16397_6826)">
      <path d="M15.807 11.3454V16.2344C15.8071 16.7502 15.6499 17.2539 15.3563 17.678C15.0628 18.1022 14.6469 18.4268 14.164 18.6084L12.432 19.2604C12.3553 19.2894 12.2726 19.2993 12.1911 19.2893C12.1097 19.2792 12.0319 19.2496 11.9644 19.2029C11.897 19.1562 11.8418 19.0938 11.8038 19.021C11.7658 18.9483 11.7459 18.8675 11.746 18.7854V12.8294L8.35405 14.0684C8.15916 14.1402 7.99099 14.2701 7.87226 14.4405C7.75352 14.611 7.68992 14.8137 7.69005 15.0214V23.0074C7.69005 23.7124 8.39005 24.2024 9.05305 23.9604L19.214 20.2474C19.7025 20.0692 20.1244 19.745 20.4224 19.3189C20.7204 18.8928 20.8802 18.3854 20.88 17.8654V7.7194C20.8797 8.23914 20.7197 8.74622 20.4217 9.17207C20.1238 9.59791 19.7022 9.92197 19.214 10.1004L15.807 11.3454ZM15.807 10.8134V9.3464C15.807 8.83018 15.6496 8.32625 15.3556 7.90188C15.0617 7.47751 14.6453 7.1529 14.162 6.9714L12.434 6.3214C12.3573 6.29268 12.2747 6.28296 12.1933 6.29309C12.112 6.30321 12.0343 6.33287 11.9669 6.37954C11.8995 6.42621 11.8443 6.4885 11.8062 6.5611C11.7681 6.63369 11.7482 6.71442 11.748 6.7964V11.3864C11.748 12.0874 11.053 12.5774 10.393 12.3424L3.79705 9.9864C3.59981 9.91605 3.42915 9.78641 3.30849 9.61525C3.18782 9.4441 3.12305 9.23982 3.12305 9.0304V1.0384C3.12305 0.337403 3.81805 -0.152597 4.47905 0.0834027L19.197 5.3394C19.61 5.48696 19.9776 5.73899 20.2642 6.07092C20.5507 6.40285 20.7464 6.80336 20.832 7.2334C20.696 8.0944 19.782 9.3614 19.042 9.6314L15.807 10.8134Z" fill="white" fill-opacity="1" />
    </g>
    <defs>
      <clipPath id="clip0_16397_6826">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Dynamics365Logo"
}
</script>

<style>

</style>
