<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7617 2.41992V13.5749L20 2.42076L10.7617 2.41992ZM0 2.42159C0 4.97159 2.06917 7.04159 4.61917 7.04159C7.16917 7.04159 9.2375 4.97159 9.23833 2.42159H0ZM9.23833 6.42742L0 17.5799H9.23833V6.42742ZM15.38 12.9608C12.8275 12.9608 10.7617 15.0274 10.7617 17.5799H20C20 15.0299 17.9333 12.9608 15.3808 12.9608H15.38Z" fill="white" fill-opacity="1" />
  </svg>
</template>

<script>
export default {
  name: "ZendeskLogo"
}
</script>

<style>

</style>
