<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_16120_9573)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.27839 4.52389C8.92162 3.8543 9.81685 3.43965 10.8059 3.43965C12.1245 3.43965 13.2674 4.17224 13.8828 5.26381C14.429 5.01962 15.0207 4.89354 15.6191 4.89385C17.9927 4.89385 19.9194 6.83522 19.9194 9.23079C19.9194 11.6264 17.9927 13.5677 15.6191 13.5677C15.3289 13.5677 15.0462 13.5386 14.7692 13.4835C14.2308 14.4432 13.2015 15.0952 12.0293 15.0952C11.5521 15.0961 11.081 14.9879 10.652 14.7787C10.1062 16.0608 8.83517 16.9618 7.35532 16.9618C5.80953 16.9618 4.49817 15.9875 3.99268 14.6175C3.76769 14.665 3.53837 14.6889 3.30843 14.6888C1.4696 14.6888 -0.0175781 13.1796 -0.0175781 11.3262C-0.0175781 10.0807 0.652019 8.99651 1.64543 8.41044C1.43467 7.92495 1.32619 7.40125 1.32674 6.87199C1.32674 4.73282 3.06301 3.00391 5.2022 3.00391C6.45495 3.00391 7.57583 3.60097 8.27913 4.5277" fill="white" fill-opacity="1" />
    </g>
    <defs>
      <clipPath id="clip0_16120_9573">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "SalesforceLogo"
}
</script>

<style>

</style>
