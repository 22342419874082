<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.27394 2.12761C6.26756 2.13053 6.26118 2.13345 6.25481 2.13637C4.81473 2.79614 3.58756 3.57982 2.62625 4.45212C0.355388 6.45478 -0.306203 8.88657 0.122937 11.9978C0.516316 14.5012 1.58917 16.7184 3.18056 18.5601C3.18056 18.5601 3.21632 18.578 3.19844 18.578L5.34741 7.22331L5.8448 4.59517C6.00573 3.84417 6.27394 2.12761 6.27394 2.12761ZM16.8594 4.43424C16.8594 4.26438 16.8507 4.09311 16.8323 3.92349C16.8318 3.9182 16.8312 3.91288 16.8306 3.90759C16.8241 3.84949 16.8164 3.7916 16.8075 3.73404C16.6363 2.62058 16.0195 1.62695 14.6422 1.62695C12.7952 1.62695 11.0841 3.66439 10.4188 6.87894C10.4014 6.96324 10.3846 7.04835 10.3687 7.13425L9.74283 10.8177C10.2971 11.3541 11.0839 11.6581 12.0495 11.6581C14.7673 11.6581 16.8594 7.79584 16.8594 4.43424ZM22.1342 2.95013C22.0627 2.87861 22.0985 2.91437 22.0985 2.91437C22.1521 3.28987 22.17 3.66537 22.17 4.05874C22.17 10.0309 18.2183 15.4846 12.2461 15.4846C12.2432 15.4846 12.2403 15.4846 12.2374 15.4846C12.0621 15.4844 11.8904 15.479 11.723 15.469C11.7207 15.4689 11.7183 15.4687 11.716 15.4686C10.7168 15.408 9.87066 15.1832 9.32343 14.9255C9.22724 14.8802 9.14028 14.8339 9.06335 14.7872H8.99183L7.59713 21.9932L7.65077 22.029C9.18852 22.8336 10.9051 23.4416 12.7468 23.7634C15.9654 24.2283 18.2183 23.4952 20.0422 21.3495C21.8481 19.3111 23.1713 15.9316 23.815 11.8727C24.2978 7.921 23.8687 4.97067 22.1342 2.95013Z" fill="white" fill-opacity="0.8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8594 4.43424C16.8594 7.79584 14.7495 11.6581 12.0495 11.6581C14.7673 11.6581 16.8594 7.79584 16.8594 4.43424ZM9.74283 10.8177C10.2971 11.3541 11.0839 11.6581 12.0495 11.6581C11.066 11.6581 10.2971 11.3362 9.74283 10.8177ZM16.8075 3.73404C16.6363 2.62058 16.0195 1.62695 14.6422 1.62695C12.7952 1.62695 11.0841 3.66439 10.4188 6.87894C10.4014 6.96324 10.3846 7.04835 10.3687 7.13425C10.9945 3.77265 12.7468 1.64483 14.6422 1.64483C16.0195 1.63002 16.6363 2.62105 16.8075 3.73404ZM12.2374 15.4846C12.0621 15.4844 11.8904 15.479 11.723 15.469C11.7207 15.4689 11.7183 15.4687 11.716 15.4686C11.8829 15.479 12.0539 15.4846 12.2283 15.4846C12.2313 15.4846 12.2344 15.4846 12.2374 15.4846ZM9.32343 14.9255C9.22724 14.8802 9.14028 14.8339 9.06335 14.7872H9.04547C9.1279 14.8333 9.22085 14.8797 9.32343 14.9255ZM5.8448 4.57729C5.98737 3.84662 6.12994 3.00937 6.25481 2.13637L6.27394 2.12761C6.27394 2.12761 6.00573 3.84417 5.8448 4.59517L5.34741 7.22331L5.8448 4.57729Z" fill="white" fill-opacity="0.8"/>
  </svg>
</template>

<script>
export default {
  name: "PlentyMarketsLogo"
}
</script>

<style>

</style>
